import { createStore } from 'vuex'

export default createStore({
	state: {
		playerName: '',
		decks: [],
		roster: [],
		self: {},
		hand: [],
		board: {
			black: {},
			whites: [],
			deckId: ''
		}
	},
	mutations: {
		setRoster (state, value){
			state.roster = value;
		},
		setBoard (state, value){
			state.board = value;
		},
		setHand (state, value){
			state.hand = value;
		},
		setSelf (state, value){
			state.selt = value;
		},
		setSocket (state, value){
			state.socket = value;
		},
		setPlayerName (state, value) {
			state.playerName = value;
		}
	},
	actions: {
	},
	modules: {
	}
})
