<template>
    <div class="controls">
        <div style="text-align: center;">
        <button
            class="push_button blue"
            v-on:click="handleAdvance()"
            v-bind:disabled="
            !(
                (board.judge === 0 && roster.length >= 3) ||
                (self.id === board.judge && board.picking)
            )
            "
        >
            {{ board.judge === 0 ? "Start Game" : "Next Turn" }}
        </button>

        <div class="controls__game-info">
            <div class="controls__label-container">
                <span class="controls__label">
                    <span>{{ board.deckId + " Deck" }}</span>
                </span>
                <span class="controls__label">
                    <span style="margin-right: 0.5rem;">Questions Left</span>
                    <span>{{ board.questions_remaining }}</span>
                </span>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import Store from "@/store/index.js";

export default {
  name: "Controls",
  props: {
    msg: String,
  },
  store: Store,
  computed: {
    roster: function() {
      return this.$store.state.roster;
    },
    board: function() {
      return this.$store.state.board;
    },
    self: function() {
      return this.$store.state.self;
    },
  },
  methods: {
    handleAdvance: function() {
      this.$store.state.socket.send(
        JSON.stringify({
          type: "advance",
          room: this.$store.state.board.gameId,
        })
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.controls__game-info {
    display: inline-flex;
    color: #353535;
    background: #f5f2f2;
    padding: .25rem 1rem;
    letter-spacing: .25px;
    font-weight: 500;
}

.controls__label-container {
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
}

.controls__label {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid;
    margin-right: 0.5rem;
    padding-right: 0.5rem;

	&:last-child {
		border-right: none;
		padding-right: 0;
		margin-right: 0;
	}
}

.push_button {
	position: relative;
	width:175px;
	height:40px;
	text-align:center;
	color:#FFF;
	text-decoration:none;
	line-height:43px;
	display: block;
	margin: 1rem auto 1.25rem;
	outline: none;
	cursor: pointer;
	font-weight: bold;
	letter-spacing: .75px;
	font-size: 1.1rem;
}
.push_button:before {
	background:#f0f0f0;
	background-image: linear-gradient(180deg, #DEDEDE, #f0f0f0);
	border-radius:5px;
	box-shadow:0 1px 2px rgba(0, 0, 0, .5) inset, 0 1px 0 #FFF;
	position: absolute;
	content: "";
	left: -8px; 
    right: -8px;
	top: -8px; 
    bottom: -12px;
	z-index: -1;
}

.push_button:active {
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset, 0 -1px 0 rgba(255, 255, 255, .1) inset;
	top:7px;
}
.push_button:active:before{
	top: -15px;
	bottom: -5px;
	content: "";
}

.blue {
	text-shadow:-1px -1px 0 #2C7982;
	background: #3EACBA;
	border:1px solid #379AA4;
	background-image:linear-gradient(top, #48C6D4, #3EACBA);
	border-radius:5px;
	box-shadow:0 1px 0 rgba(255, 255, 255, .5) inset, 0 -1px 0 rgba(255, 255, 255, .1) inset, 0 6px 0 #338A94, 0 4px 2px rgba(0, 0, 0, .5);
}

.blue:not(:disabled):not([disabled]):hover {
	background: #48C6D4;
	background-image:linear-gradient(top, #3EACBA, #48C6D4);
}

button:disabled,
button[disabled]{
    cursor: default;
    color: #d0d0d0;
}
</style>
