<template>
    <div class="cards-against-retro">
        <!-- HEADER -->
        <div class="header">
            CARDS AGAINST RETROSPECTIVE
        </div>
        <div class="game-board">
            <Controls />
            <Board />
            <Hand />
        </div>   
    </div>
</template>

<script>
// @ is an alias to /src
import Controls from '@/components/CardsAgainstRetro/Controls.vue'
import Board from '@/components/CardsAgainstRetro/Board.vue'
import Hand from '@/components/CardsAgainstRetro/Hand.vue'
import Store from '@/store/index.js'

export default {
    name: 'Home',
    components: {
    Controls,
    Board,
    Hand
    },
    store: Store,
    computed: {
        roster: function() {
            return this.$store.state.roster
        }
    },
    mounted() {
        // console.log(this.$route.query)

        // // const urlState = this.$route.query.parse(window.location.search.substring(1));
        // // const urlName = urlState && urlState.name;
        // const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        // const socket = new WebSocket(process.env.VUE_APP_SERVER_HOST || protocol + '//' + window.location.host);
        // const that = this;

        // console.log(this.$store.state.playerName);
    
        // socket.onopen = function () {
        // //   if (urlName) {
        // //     this.updateName({ target: { value: urlName } }, this.handleJoin);
        // //   }
        //     socket.send(JSON.stringify({ 
        //         type: 'join', 
        //         room: '', 
        //         name: that.$store.state.playerName, 
        //         gameType: 'Base', 
        //         deck: "PRM"
        //     }));
        // }

        // that.$store.commit('setSocket', socket);

        // socket.onmessage = function (msg) {
        //     const json = JSON.parse(msg.data);
        //     console.log(json.data);
        //     if (json.type === 'roster') {
        //         that.$store.commit('setRoster', json.data);
        //     }
        //     else if (json.type === 'hand') {
        //         that.$store.state.hand = json.data;
        //     }
        //     else if (json.type === 'board') {
        //         that.$store.state.board = json.data;
        //         //window.history.replaceState('', '', `?room=${json.data && json.data.gameId}${urlName ? `&name=${urlName}` : ''}`);
        //     }
        //     else if (json.type === 'join_ack') {
        //         that.$store.state.self = json.data;
        //     } else if (json.type === 'join_refuse') {
        //         //this.setState({ self: { msg: 'A player with this name is already connected, please choose another' } });
        //     }
        // }
    }
}
</script>

<style scoped lang="scss">

.header {
    background: #2e3a5a;
    padding: .5rem;
    color: white;
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 2rem;
}

.game-board {
    max-width: 1200px;
    margin: 0 auto;
}
</style>
