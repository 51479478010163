<template>
    <div class="board">
        <div class="board_game-invite" v-if="board.judge == 0">
            Invite your Friends: <a v-bind:href="inviteUrl" target="_blank">{{ inviteUrl }}</a>
        </div>
        <div style="display: flex; flex-wrap: wrap;">
            <Roster />
            <div class="board__display">
                <div class="board__display-info">
                    <span v-if="board.judge == 0">Waiting for game start (3 players minimum)...</span>
                    <span v-if="!board.gameOver && judge && !board.selected && !board.allPlayersReady"><strong>{{ judge }} is judge.</strong> Waiting for players to play cards...</span>
                    <span v-if="board.gameOver">Game over.</span>
                    <span v-if="!board.gameOver && judge && board.selected"><strong>{{ judge.name }} picked the winner!</strong> Waiting for {{ judge.name }} to advance to the next turn...</span>
                    <span v-if="!board.gameOver && judge && !board.selected && board.allPlayersReady"><strong>All players played!</strong> Waiting for {{ judge.name }} to pick the winner...</span>
                </div>
                <div style="display: flex; flex-wrap: wrap;">
                    <div class="board__card">
                        <div style="padding: 4px 0px;">{{ board.black.text }}</div>
                        <!-- <div class="alignBottom">Pick {{ board.black.pick }} </div> -->
                    </div>
                    <div class="board__card board__card--white" 
                        v-for="(card, index) in board.whites" 
                        :key="card" 
                        v-on:click="pickCard( index )"
                        v-bind:class="[{ 'board__card--winner': card.winner }, { 'board__card--pickable': self.id === board.judge },'']"
                        >
                            <div v-if="card.cards" v-html="card.cards"></div>
                            <div class="board__card-info" v-if="card.playerIndex !== undefined">{{ roster[card.playerIndex].name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Roster from '@/components/CardsAgainstRetro/Roster.vue'
import Store from '@/store/index.js'

export default {
    name: "Board",
    components: {
        Roster
    },
    store: Store,
    computed: {
        roster: function() {
            return this.$store.state.roster;
        },
        board: function() {
            return this.$store.state.board;
        },
        judge: function() {
            return this.$store.state.board.judge ? this.$store.state.roster.find( player => player.id == this.$store.state.board.judge).name : '';
        },
        self: function() {
            return this.$store.state.self;
        },
        inviteUrl: function(){
            return window.location.origin + '/#/?room=' + this.$store.state.board.gameId;
        }
    },
    props: {
        msg: String,
    },
    methods: {
        pickCard: function( id ){
            this.$store.state.socket.send(JSON.stringify({ 
                type: 'select', 
                room: this.$store.state.board.gameId, 
                data: id 
            }));
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.board__display {
    flex-grow: 1;
    width: 75%;
    background: #475885;
    color: #fff;
    padding: 8px;
    margin: 8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .50);
    text-align: center;
    border-radius: 3px;
    margin-right: 0;
}

.board__display-info {
    text-align: left;
    border-bottom: solid 1px #FFF;
    margin: 0 .5rem .5rem;
    padding-bottom: .25rem;
}

.board__card {
    background: #000;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin: 5px;
    padding: 1em;
    text-align: left;
    min-height: 167px;
    width: 220px;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    flex: 0 0 auto;
    padding-bottom: 2rem;

    /deep/ img {
        max-width: 100%;
    }
}

.board__card--pickable {
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.2);
        position: relative;
        z-index: 1;
    }
}

.board__card--white {
    background: #fff;
    color: #000;
}

.board__card--winner {
    background: #57d262;
}

.board__card-info {
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem;
}

</style>
