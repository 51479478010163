import { createRouter, createWebHashHistory } from 'vue-router'
import Cards from '../views/CardsAgainstRetro.vue'
import SpillTheTea from '../views/SpillTheTea.vue'

const routes = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/CardsAgainstRetro',
    name: 'Cards Against Retro',
    component: Cards
  },
  {
    path: '/spillTheTea',
    name: 'Spill The Tea',
    component: SpillTheTea
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router