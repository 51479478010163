<template>
	<div class="app" v-if="route == undefined">
        <img class="app__logo" src="@/assets/retrogames-logo.png" />
        <div class="app__room-info" v-if="roomId"> 
            You are Joining Room:
            <span class="app__room-name"> {{ roomId }} </span> 
            <span class="app__leave-room" v-on:click="leaveRoom()"> leave room </span>
        </div>
        <div class="app__choices" v-bind:class="{'app__choices--disabled' : roomId}">
            <label class="app__choice">
                <input type="radio" value="CardsAgainstRetro" v-model="gameType" v-bind:disabled="roomId ? true : false" />
                <div class="app__choice-container">
                    <span> Cards Against Retro </span>
                    <img src="@/assets/cards-against-retro-logo.png" />
                    <div class="app__deck-container">
                        <select ref="CARDecks">
                            <option v-for="deck in CARDecks" :key="deck">{{ deck }} </option>
                        </select>
                        <a href="https://docs.google.com/spreadsheets/d/1I-VM0E-vMio1gxh9PTgrIggu_0l3H-Ui87GZ41yteoY/edit?usp=sharing" target="_blank" title="click here to edit decks">
                            <img class="app__deck-config" src="@/assets/gear-icon.png"/>
                        </a>
                    </div>
                </div>
            </label>
            <label class="app__choice">
                <input type="radio" value="SpillTheTea" v-model="gameType" v-bind:disabled="roomId ? true : false"/>
                <div class="app__choice-container">
                    <span> Spill The Tea </span>
                    <img src="@/assets/spill-the-tea-logo.png" />
                    <div class="app__deck-container">
                        <select ref="STTDecks">
                            <option v-for="deck in STTDecks" :key="deck">{{ deck }} </option>
                        </select>
                        <a href="https://docs.google.com/spreadsheets/d/1Bs0Z0LTeERpjVE-2rwD9ah_beJolSdxoBDKUxio2afI/edit?usp=sharing" target="_blank" title="click here to edit decks">
                            <img class="app__deck-config" src="@/assets/gear-icon.png" />
                        </a>
                    </div>
                </div>
            </label>
        </div>
        <div class="app__player-name-container" v-bind:class="{'app__player-name--taken' : nameTaken }">
            <span class="app__player-warning"> {{ playerName }} is taken. </span>
            <input class="app__player-name" v-model="playerName" v-bind:class="{'app__player-name--taken' : nameTaken }" type="text" placeholder="Player's Name" ref="userName" />
            <button class="app__join-button" v-on:click="join()"> Join </button>
        </div>
        <div class="app__instructions">
            <h2> How To Play </h2>
            <h3> Cards Against Retro </h3>
            <ol class="app__game-mechanics">
                <li> Pick a Deck (you can create your own using the gear icon). Once you're in a room, you can share the room link with other players </li>
                <li> Minimum of 3 players, once ready to play, anyone can press the <b>"Start Game"</b> at the top of the board. </li>
                <li> There's a Judge at every turn, players would have to select an image at hand that best describes their feelings/perspective to the given topic (black card.)</li>
                <li> Once all player's have given their card, the Judge picks the winner. </li>
                <li> The Group is encouraged to discuss the topic and the answers, once done. the current judge should hit <b>"Next Turn"</b> at the top of the board.</li>
                <li> The Game goes on until there's no more Black/White Cards. </li>
                <li> <b>NOTE:</b> If a player gets disconnected, just rejoin using the same link with the room id and use the <b>EXACT NAME</b> that you have.</li>
            </ol>

            <h3> Spill The Tea </h3>
            <ol class="app__game-mechanics">
                <li> Pick a Deck (you can create your own using the gear icon). Once you're in a room, you can share the room link with other players </li>
                <li> Minimum of 3 players, once ready to play, anyone can press the <b>"Start Game"</b> at the top of the board. </li>
                <li> There's a Judge at every turn, the judge picks a question that every player would need to answer.</li>
                <li> Players are given a textfield to enter their response, once done player should hit <b>"Submit"</b>.</li>
                <li> Once all player's have given their Answer, the Judge Goes over all the response and <b>"Assign"</b> who's answer is what. Every right guess gives the judge a point. </li>
                <li> The Group is encouraged to discuss the question and the answers, once done. the current judge should hit <b>"Next Turn"</b> at the top of the board.</li>
                <li> The Judge doesn't have to guess all the answers, you can just move on with the Next Turn.</li>
                <li> The Game goes on until there's no more Question Cards. </li>
                <li> <b>NOTE:</b> If a player gets disconnected, just rejoin using the same link with the room id and use the <b>EXACT NAME</b> that you have.</li>
            </ol>
        </div>
	</div>
	<router-view/>
</template>


<script>
// @ is an alias to /src
import axios from "axios";
import Store from '@/store/index.js'

export default {
    name: 'PRM Retro',
    components: {
    },
    store: Store,
    computed: {
        socket: function() {
            return this.$store.state.socket
        },
		playerName: {
			get() {
				return this.$store.state.playerName;
			},
			set(value) {
				this.$store.commit('setPlayerName', value);
			}
        },
		route: function() {
			return this.$route.name;
		}
    },
    data() {
        return {
            gameType: 'CardsAgainstRetro',
            CARDecks: [],
            STTDecks: [],
            endpoint: process.env.VUE_APP_SERVER_ENDPOINT || window.location.origin,
            roomId: '',
            nameTaken: false
        }
    },
	methods: {
		join: function(){
			let room = this.$route.query.room || '';

            if (this.$store.state.playerName == '') {
                return false;
            }

			this.$store.state.socket.send(JSON.stringify({ 
                type: 'join', 
                room: room, 
                name: this.$store.state.playerName, 
                game: this.gameType,
                deck: this.gameType == 'CardsAgainstRetro' ? this.$refs.CARDecks.value : this.$refs.STTDecks.value
            }));
		},
        leaveRoom: function(){
            this.$router.push({ path: '/', query: {} });
            this.roomId = '';
            this.updateDecks();
        },
        updateDecks: function(){
            
            
            axios.all([
                axios.get(this.endpoint + "/CAR/decks"),
                axios.get(this.endpoint + "/STT/decks"),
            ])
            .then(responseArr => {
                let room = this.$route.query.room || '';

                this.CARDecks = responseArr[0].data;
                this.STTDecks = responseArr[1].data;
                
                if (room && room != ''){
                    
                    axios
                        .get(this.endpoint + "/rooms?room=" + room)
                        .then((response) => {
                            if (response.data.info == undefined) return false;
                            this.gameType = response.data.info.type;
                            this.roomId = room;

                            if ( response.data.info.type == 'CardsAgainstRetro' ) {
                                this.$refs.CARDecks.value = response.data.info.board.deckId;
                            } else {
                                this.$refs.STTDecks.value = response.data.info.board.deckId;
                            }

                            this.$refs.CARDecks.setAttribute("disabled", "disabled");
                            this.$refs.STTDecks.setAttribute("disabled", "disabled");
                            this.$refs.userName.focus();
                    });
                } else {
                    this.$refs.CARDecks.removeAttribute("disabled");
                    this.$refs.STTDecks.removeAttribute("disabled");
                }
            });

        }
	},
    watch: {
        gameType: function (val) {
            console.log(val);
        },
        playerName: function(){
            this.nameTaken = false;
        }
    },
    mounted() {
        // const urlState = this.$route.query.parse(window.location.search.substring(1));
        // const urlName = urlState && urlState.name;
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const socket = new WebSocket(process.env.VUE_APP_SERVER_HOST || protocol + '//' + window.location.host);
        const that = this;
    
		this.$store.commit('setSocket', socket);
        this.updateDecks();

        socket.onopen = function () {
        //   if (urlName) {
        //     this.updateName({ target: { value: urlName } }, this.handleJoin);
        //   }
			//that.join();
        }

        socket.onmessage = function (msg) {
            const json = JSON.parse(msg.data);
            console.log(json);
            if (json.type === 'roster') {
                that.$store.commit('setRoster', json.data);
            }
            else if (json.type === 'hand') {
                that.$store.state.hand = json.data;
            }
            else if (json.type === 'board') {
                that.$store.state.board = json.data;
                //window.history.replaceState('', '', `?room=${json.data && json.data.gameId}${urlName ? `&name=${urlName}` : ''}`);
            }
            else if (json.type === 'join_ack') {
                that.$store.state.self = json.data;
				console.log('joined');
				that.$router.push({ path: json.data.game, query: { room: json.data.gameId } })
            } else if (json.type === 'join_refuse') {
                that.nameTaken = true;
                console.log('bleh');
                //this.setState({ self: { msg: 'A player with this name is already connected, please choose another' } });
            }
        }
    }
}
</script>

<style lang="scss">

body {
    background: url("assets/email-pattern.png");
    margin: 0 0 3rem;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0 auto;
}

.app__logo {
    margin-top: 4rem;
    display: block;
    margin: 4rem auto 1rem;
    width: 350px;
}

.app__room-info {
    background: white;
    display: inline-block;
    margin: 0 auto 2rem;
    padding: 1rem 2rem 1rem;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, .2);
}

.app__room-name {
    font-size: 1.5rem;
    display: block;
    margin-bottom: .5rem;
    font-weight: bold;
    text-decoration: underline;
}

.app__leave-room {
    color: rgb(214, 94, 94);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.app__choices {
    display: flex;
    justify-content: center;
}

.app__choice {
    margin-right: 3rem;

    input[type="radio"] {
        display: none;
    }

    &:last-child {
        margin-right: 0;
    }

    .app__choices--disabled & {
        opacity: .6;
    }
}

.app__choice-container {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 1rem;
    border-radius: .5rem;
    cursor: pointer;
    border: solid 2px white;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, .2);

    .app__choice input:checked + & {
        border: solid 2px #f9f900;
    }

    .app__choices--disabled & {
        box-shadow: none;
        cursor: default;
    }
}

.app__deck-container {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    padding-top: 1rem;

    &:before {
        content: 'Choose a deck:';
        display: block;
        width: 100%;
        flex: 0 0 100%;
        position: absolute;
        top: 0;
        left: 1px;
        text-align: left;
        font-size: 12px;
    }

    select {
        padding: .25rem;
        width: 100%;
    }

    a {
        display: inline-flex;
    }
}

.app__deck-config {
    margin-left: .5rem;
    width: 26px;
    height: 26px;
}

.app__player-name-container {
    margin: 3rem auto 1rem;
}

.app__player-warning {
    display: none;

    .app__player-name--taken & {
        display: inline-block;
    }
}

.app__player-name {
    padding: .5rem;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: .5rem;
    outline: none;
    display: block;
    margin: 0 auto 1rem;
    text-align: center;
    border: solid 2px #dad4d4;

    .app__player-name--taken & {
        border: solid 2px #c75a5a;
        position: relative;
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;

        &:before {
            content: 'name is taken';
            display: block;
            position: absolute;
        }
    }
}


.app__join-button {
    display: block;
    margin: 1rem auto;
	box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	background-color:#007dc1;
	border-radius:3px;
	border:1px solid #124d77;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #154682;

    &:hover {
        background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
        background-color:#0061a7;
    }

    &:active {
        position:relative;
        top:1px;
    }
}

.app__instructions {
    margin-top: 3rem;
}

.app__game-mechanics {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
    background: white;
    padding: 1rem;
    padding-left: 2.5rem;
}

@keyframes shake {
	10%, 90% {
        transform: translate3d(-1px, 0, 0);
	}
	
	20%, 80% {
        transform: translate3d(2px, 0, 0);
	}
  
	30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
	}
  
	40%, 60% {
        transform: translate3d(4px, 0, 0);
	}
}
</style>
