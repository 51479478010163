<template>
    <div class="answer" v-if="self.id !== board.judge">
        <h3> YOUR ANSWER </h3>
        <div>
            <textarea v-model="userResponse" class="answer__text" v-bind:class="{'answer__text--disabled': (board.question == '')}" v-bind:disabled="board.question == '' ? true : false"></textarea>
            <button v-on:click="submitResponse" class="answer__button" v-bind:disabled="board.question == '' ? true : false">SUBMIT</button>
        </div>
    </div>
</template>

<script>
import Store from '@/store/index.js'

export default {
	name: "Answer",
	store: Store,
    data: function () {
        return {
            userResponse: ''
        }
    },
    computed: {
        board: function() {
            return this.$store.state.board;
        },
        hand: function() {
            return this.$store.state.hand
        },
		self: function() {
            return this.$store.state.self
        }
    },
	methods: {
		handlePlay : function ( id ) {
			this.$store.state.socket.send(JSON.stringify({ 
				type: 'topic', 
				room: this.$store.state.board.gameId,
				data: id
			}));
		},
        submitResponse: function() {
            this.$store.state.socket.send(JSON.stringify({ 
				type: 'play', 
				room: this.$store.state.board.gameId,
				data: this.userResponse
			}));
            this.userResponse = '';
        }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.answer {
    background-color: #e4e4e4; 
    border-radius: 0.5rem; 
    margin-top: 1rem; 
    padding-bottom: 1rem;
}

h3 {
    text-align: center;
    padding: 1rem 0px;
    font-size: 1.75rem;
    margin: 0px;
    color: rgb(34, 34, 34);
}

.answer__text {
    background-attachment: local;
    background-image:
        linear-gradient(to right, white 10px, transparent 10px),
        linear-gradient(to left, white 10px, transparent 10px),
        repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
    line-height: 31px;
    padding: 8px 10px;
    outline: none;
    border-radius: .5rem;
    border: solid 1px #cabdbd;
    box-shadow: 4px 4px 1px rgba(0, 0, 0, .15);
    max-width: 90%;
    width: 50%;
    height: 250px;
}

.answer__button {
    display: block;
    margin: 1rem auto;
	box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	background-color:#007dc1;
	border-radius:3px;
	border:1px solid #124d77;
	cursor:pointer;
	color:#ffffff;
	font-size:17px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #154682;
}

.answer__button:hover {
	background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
	background-color:#0061a7;
}

.answer__button:active {
	position:relative;
	top:1px;
}

.answer__text--disabled {
    opacity: .5;
}

</style>