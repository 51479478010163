<template>
    <div class="spill-the-tea">
        <!-- HEADER -->
        <div class="header">
            SPILL THE TEA
        </div>
        <div class="game-board">
            <Controls />
            <Board />
            <Hand />
            <Answer />
        </div>   
    </div>
</template>

<script>
// @ is an alias to /src
import Controls from '@/components/SpillTheTea/Controls.vue'
import Board from '@/components/SpillTheTea/Board.vue'
import Hand from '@/components/SpillTheTea/Hand.vue'
import Answer from '@/components/SpillTheTea/Answer.vue'
import Store from '@/store/index.js'

export default {
    name: 'Home',
    components: {
    Controls,
    Board,
    Hand,
    Answer
    },
    store: Store,
    computed: {
        roster: function() {
            return this.$store.state.roster
        }
    },
    mounted() {
        console.log(this.$route.query)
        if (this.$store.state.playerName == ''){
            this.$router.push({ path: '/#/', query: { room: (this.$route.query.room || '') } });
        }
    }
}
</script>

<style scoped lang="scss">

.header {
    background: #2e3a5a;
    padding: .5rem;
    color: white;
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 2rem;
}

.game-board {
    max-width: 1200px;
    margin: 0 auto;
}
</style>
