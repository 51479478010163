<template>
    <div class="roster">
        <h3 class="roster__title">Players</h3>
        <ul class="roster__players">
            <li class="roster__player" v-for="player in roster" :key="player.id" v-bind:class="{ 'roster__player--self' : player.id == self.id }"  >
                <span class="roster__indicator" v-bind:class="{ 'roster__indicator--connected' : player.readyState == 1 }"></span>
                {{ player.name }}
                <div class="roster__player-score">{{ player.score }}p</div>
            </li>
        </ul>
    </div>
</template>

<script>
import Store from '@/store/index.js'

export default {
    name: "Roster",
    store: Store,
    computed: {
        roster: function() {
            return this.$store.state.roster
        },
        self: function() {
            return this.$store.state.self
        }
    },
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.roster {
    flex-grow: 1;
}

.roster__title {
    background: #FFF;
    margin-bottom: 0;
    margin-top: .5rem;
    
}

.roster__players {
    margin: 0;
    padding: 0;
}

.roster__player {
    display: flex;
    height: 30px;
    font-weight: 400;
    align-items: center;
    padding: 0 .5rem;
    background: #fff;
    border-bottom: solid 1px #dadada;

    &:first-child {
        border-top: solid 1px #dadada;
    }
}

.roster__player--self {
    font-weight: bold;
}

.roster__indicator {
    background: #ff0000;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px inset rgba(0, 0, 0, .50);
    margin-right: .5rem;
}

.roster__indicator--connected {
    background: #00ff00;
}

.roster__player-score {
    margin-left: auto;
}
</style>
