<template>
  <div class="hand" v-if="self.id === board.judge">
    <h3> QUESTIONS </h3>
    <div class="hand__cards">
        <div class="hand__card" 
			v-for="(card, index) in hand" 
			:key="card" 
			v-bind:class="[
				//{'hand__card--judge' : (self.id === board.judge)}, 
				{'hand__card--pickable': (self.id === board.judge)} ,
				''
				]"
            v-on:click="handlePlay( index )"
			>
				<div v-html="card">
				</div>
        </div>
    </div>
  </div>
</template>

<script>
import Store from '@/store/index.js'

export default {
	name: "HelloWorld",
	store: Store,
	props: {
		msg: String,
	},
    computed: {
        hand: function() {
            return this.$store.state.hand
        },
		self: function() {
            return this.$store.state.self
        },
		board: function() {
            return this.$store.state.board
        }
    },
	methods: {
		handlePlay : function ( id ) {
			this.$store.state.socket.send(JSON.stringify({ 
				type: 'topic', 
				room: this.$store.state.board.gameId,
				data: id
			}));
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hand {
    background-color: rgb(255, 255, 255); 
    border-radius: 0.5rem; 
    margin-top: 1rem; 
    padding-bottom: 1rem;
    box-shadow: 0px 0px 4px inset rgba(0, 0, 0, .5);
}

h3 {
    text-align: center;
    padding: 1rem 0px;
    font-size: 1.75rem;
    margin: 0px;
    color: rgb(34, 34, 34);
}

.hand__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px auto;
    opacity: 1;
}

.hand__card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .50);
    margin: 5px;
    padding: 1em;
    text-align: left;
    min-height: 167px;
    width: 220px;
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    font-family: Arial,Helvetica,sans-serif;
    flex: 0 0 auto;

    /deep/ img {
        max-width: 100%;
    }
}

.hand__card--pickable {
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.2);
        position: relative;
        z-index: 1;
    }
}

.hand__card--judge {
	opacity: .5;
}

</style>
